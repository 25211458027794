
function App() {
  return (
    <div className="bg-neutral-800 w-full h-full grid">
      <div className="w-3/4 my-auto ml-8 md:ml-20">
        <h1 className="text-28 md:text-4xl font-bold wordBreak text-white">
          powered by engagementstreams.io
        </h1>
       {/* <p className="text-white text-24">
          Coming Soon
        </p> */}
      </div>
    </div>
  );
}

export default App;
